import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2021",
      title: "Diploma Computer Programing",
      place: "Udemy",
      desc: "React - Redux, React Native",
    },
    {
      yearRange: "2019",
      title: "Diploma in Website Development",
      place: "EDteam Academy",
      desc: "JavaScript, C#, CSS, Html5, WordPress",
    },
    {
      yearRange: "2018",
      title: "Diploma in Computer Programming",
      place: "El Camino Dev Academy",
      desc: "Zamarin Form, Azure",
    },
    {
      yearRange: "2014",
      title: "Diploma in Website Development",
      place: "IDAT Academy",
      desc: "Web Development",
    },
    {
      yearRange: "2005",
      title: "Bachelor in Software Engineering",
      place: "Military University - Peru",
      desc: "Software Engineering",
    },
    {
      yearRange: "2001",
      title: "Bachelor in Telecom Engineering",
      place: "Military University - Peru",
      desc: "Telecommunications Engineering",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2017 - Present",
      title: "WordPress Developer",
      place: "Gotta Web Design LLC",
      desc: "Creation of new WordPress pages and update of existing ones.",
      desc2: "I designed, implemented and monitored web pages, plugins and functionalities for continuous improvements.",
      desc3: "Created elegant, creative and easy to use designs for clients.",
      desc4: "Provided ongoing efficiency and security maintenance and website interface patches to maintain viability after launch.",
      desc5: "Test strategies to improve the effectiveness of marketing efforts.",
      desc6: "Responded to and corrected issues preventing business or customer use of site or features.",
    },    
    {
      yearRange: "2016 - Present",
      title: "Software Developer",
      place: "Gotta Web Design LLC",
      desc: "Designed, prototyped, developed, tested, and maintained web-based applications with back components.",
      desc2: "Refined the software to continuously improve its performance. ",
      desc3: "Improved the front-end JavaScript functionality to provide a better user experience.",
      desc4: "React - Redux, JavaScript, working with and consuming REST APIs, and knowledge of version control software, specifically Git.",
      desc5: "Using back end with Azure or Firebase and front-end React-Redux with Material-UI or React Bootstrap.",
      desc6: "Using back end with SQL server, ASP.NET Core API and front-end .NET MVC.",
      desc7: "Using back-end Azure or Firebase and front-end Xamarin Forms for build apps projects Mobile.",
      desc8: "Build RESTful Web API Developing front-end and back-end applications.",
      desc9: "Solve system errors, developing advanced modules in the system, and customer support.",
      desc10: "Construction products, discuss, analyze strategic product development.",
      desc11: "Implementing best coding practices to improve project effectiveness.",
      desc12: "Participating in creating project plan and release plan.",
      desc13: "Developed and modified software modules base on business requirements.",
    },    
  ];

  const skills = [
    {
      name: "JavaScript",
      percent: 100,
    },
    {
      name: "C#",
      percent: 100,
    },    
    {
      name: "HTML/CSS",
      percent: 100,
    },
    
    {
      name: "React JS",
      percent: 90,
    },
    {
      name: "React Native",
      percent: 80,
    },
    {
      name: "Aspx.Net, MVC, WebApi, Net.core",
      percent: 90,
    },
    {
      name: "SQL Server, MySqlFirebase, Azure",
      percent: 90,
    },
    {
      name: "Bootstrap",
      percent: 100,
    },
    {
      name: "Website Design",
      percent: 100,
    },
    {
      name: "WordPress",
      percent: 100,
    },
    {
      name: "Photoshop",
      percent: 80,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                      <a href="https://gottawebdesign.com/" target={"_blank"}> {value.place}</a>
                  </p>
                  <ul>
                    <li>{value.desc}</li>
                    <li>{value.desc2}</li>
                    <li>{value.desc3}</li>
                    <li>{value.desc4}</li>
                    <li>{value.desc5}</li>
                    <li>{value.desc6}</li>
                    { value.desc7  &&  value.desc7.length > 1 ? <li>{value.desc7 }</li> : '' }
                    { value.desc8  &&  value.desc8.length > 1 ? <li>{value.desc8 }</li> : '' }
                    { value.desc9  &&  value.desc9.length > 1 ? <li>{value.desc9 }</li> : '' }
                    { value.desc10 &&  value.desc10.length > 1 ? <li>{value.desc10}</li> : '' }
                    { value.desc11 &&  value.desc11.length > 1 ? <li>{value.desc11}</li> : '' }
                    { value.desc12 &&  value.desc12.length > 1 ? <li>{value.desc12}</li> : '' }
                    { value.desc13 &&  value.desc13.length > 1 ? <li>{value.desc13}</li> : '' }
                  </ul>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
